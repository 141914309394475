.topheader {
  margin-bottom: 4.3rem !important;
}
.navbar {
  padding: 1rem 1rem !important;
}
.content_padding-header {
  box-shadow: rgb(0 0 0 / 4%) 0px 25px 50px -12px !important;
}

.MainHeader {
  background-color: #fff !important;
}
.header_logo img {
  height: 50px !important;
}
.header_logo_heading {
  color: #000;
  font-size: larger;
}
.header_padding {
  width: 100%;
  padding-right: 2% !important;
  padding-left: 4% !important;
  margin-right: auto;
  margin-left: auto;
}
.header_grow {
  flex-grow: 1 !important;
}
.login_btn {
  background-color: var(--clr-primary);
  padding: 0 12px;
  border-radius: 20px;
  color: #fff;
  z-index: 1;
  height: 30px;
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.MuiPaper-elevation4 {
  border-bottom: 3px solid #8d97a138 !important;
}
.header_links {
  display: flex;
  align-items: center;
  justify-content: right !important;
}
.header_link_color {
  color: #555;
  font-weight: 400 !important;
  font-size: 14px;
  cursor: pointer;
  padding: 0 17px;
  letter-spacing: 0.5px;
  border-radius: 4px;
}

.header_link_color:hover {
  opacity: 0.7;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1320;
  top: 0;
  left: 0;
  background-color: white;
  border-right: 1px solid lightgray;
  overflow-x: hidden;
  transition: 0.5s;
}

.sidenav span {
  text-decoration: none;
  font-size: 15px;
  display: block;
  transition: 0.3s;
  font-size: 14px;
  border-bottom: 1px solid #ececec;
  color: #000 !important;
  padding: 10px 15px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left !important;
}

.sidenav span:hover {
  opacity: 0.7;
}
.sidebar_box_style:hover {
  color: #fff;
  background-color: var(--clr-primary);
}

.cross_icon_style {
  text-align: right !important;
  color: black;
  margin-right: 5px;
  font-size: 20px;
  cursor: pointer;
}

.logout_Pointer_cursor {
  cursor: pointer !important;
  text-transform: uppercase;
}
.mobile_Burger_Menu {
  display: none;
}
#mySidenav {
  display: none;
}

.MuiAppBar-root {
  width: 100%;
  display: flex;
  z-index: 1076;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-direction: column;
}

.user_image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: black;
  color: #fff;
}

.dropdown_user_profile {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: lightgray;
  color: #000;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.user_account .fa {
  font-size: 29px;
}
.dropbtn {
  background-color: #04aa6d;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  z-index: 1;
  left: -93px !important;
  color: #000;
}
.dropdown-content a {
  color: black !important;
  padding: 12px 10px;
  border-bottom: 1px solid #ececec;
  text-decoration: none;
  display: block;
  text-transform: capitalize;
}

.dropdown-content a:hover {
  background-color: #3661bb !important;
  color: #fff !important;
}
.dropdown-content {
  color: #fff !important;
}

.dropdown:hover .dropdown-content {
  display: block;
}

button.btn.done_btn {
  background-color: var(--clr-primary);
  color: #000;
}
.text-center-button {
  text-align: center;
  padding-bottom: 10px;
}
img.user_profile_img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.header_currency {
  border: 2px solid #000;
  border-radius: 5px;
  padding: 9px 10px;
  font-size: 14px;
  font-weight: 400;
  color: #000;
}
.header_currency:hover {
  border: 2px solid #fff;
  color: #fff;
  background-color: var(--clr-primary);
}
.dropdown-item {
  display: block;
  width: 100%;
  padding-inline: 12px;
  clear: both;
  font-weight: 700;
  color: #000;
  text-align: inherit;
  white-space: nowrap;
  border: 0;
  border-radius: 20px;
  text-decoration: none !important;
  text-transform: uppercase;
  font-family: var(--Roboto);
}
.dropdown-item:hover {
  background-color: var(--clr-primary);
  color: #fff !important;
}
.dropdown-item a {
  color: #000;
}

.dropdown-item a:hover {
  color: #fff;
}

.header_fontSize {
  font-size: 20px !important;
}
.header_fontSize .fa {
  font-size: 20px !important;
  color: #ffffff;
}
.header_login_btn {
  border: 1px solid var(--clr-primary);
  background-color: var(--clr-primary);
  border-radius: 10px;
  color: #ffffff !important;
  font-family: var(--Roboto);
}
.submenu {
  cursor: pointer;
}

.dropdown .active {
  background-color: var(--clr-primary);
  color: #fff !important;
}
.dropdown-content_sidebar {
  display: none;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  z-index: 1;
  left: -24px !important;
}
.dropdown-content_sidebar a {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown:hover .dropdown-content_sidebar {
  display: block;
}

.moreNavBtn {
  position: relative;
  cursor: pointer;
}
.moreNavBtn .moreNavList {
  display: none;
  cursor: pointer;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 100;
  background-color: #ffffff;
  list-style-type: none !important;
  padding: 1rem;
  box-shadow: 0 0 5px #00000022;
}
.moreNavBtn:hover .moreNavList {
  display: block;
}

@media only screen and (min-width: 300px) and (max-width: 600px) {
  .header_link_color {
    padding: 0 23px !important;
  }
}
@media only screen and (min-width: 300px) and (max-width: 1000px) {
  .header_links {
    display: none;
  }
  #mySidenav {
    display: block;
  }
  .mobile_Burger_Menu {
    display: block;
    color: #424242 !important;
    font-size: 14px;
  }
  .header_logo {
    height: 27px !important;
  }
  .hamburg_left {
    cursor: pointer !important;
    position: absolute !important;
    left: 11px !important;
    font-size: 18px !important;
    top: 4px !important;
  }
  .content_padding {
    padding-top: 0% !important;
  }
  .header_logo {
    width: 139px !important;
  }
  .content_padding-header {
    box-shadow: rgb(0 0 0 / 4%) 0px 25px 50px -12px !important;
    display: none;
  }
}

@media only screen and (min-width: 600px) and (max-width: 800px) {
  .hamburg_left {
    top: 13px !important;
  }
  .dropdown-content_mobile {
    left: -30px !important;
  }
}

/* new css */
.menutop {
  height: 40px;
  border: 1px solid #eee;
}

.menu {
  padding: 0;
}

.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 999;
}

.height150 {
  height: 160px;
}

.rocket_modal {
  position: absolute;
  top: -50px;
  left: 40%;
  margin: 0;
}

.text_left {
  text-align: left;
}

.search_height_modal_35 {
  height: 35px;
  position: relative;
  border: 1px solid #ccc;
  width: 94%;
  border-radius: 3px;
}

.search_height_35 {
  height: 35px;
  position: relative;
  border: 1px solid #ccc;
  width: 97%;
  border-radius: 3px;
}

.menu li {
  display: inline-block;
  float: left;
  margin-right: 1px;
  position: relative;
}

.menu li a {
  display: block;
  padding: 0 15px;
  text-align: left;
  line-height: 40px;
  color: #333;
  font-size: 14px;
  text-decoration: none;
}

.menu li:hover a {
  background: #f3f3f3;
}

.active-menu {
  background: #fff;
  border: 1px solid var(--clr-primary);
  border-radius: 50px;
}

.menu li:hover ul a {
  background: #fff;
  color: #2f3036;
  height: 40px;
  line-height: 40px;
}

.menu li:hover ul a:hover {
  background: var(--clr-primary);
  color: #333;
  border-radius: 10px;
}

.menu li ul {
  display: none;
}

.menu li ul li {
  display: block;
  float: none;
}

.menu li ul li a {
  width: auto;
  min-width: 150px;
  padding: 0 20px;
}

.menu ul li a:hover + .hidden,
.hidden:hover {
  display: block;
}

.large_menu {
  width: 450px;
  background: #fff;
  padding: 10px !important;
  right: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
  -webkit-box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
}

.large_menu li {
  width: 44%;
  float: left !important;
  margin-right: 5% !important;
  border-bottom: 1px solid #e9e9e9;
}

.show-menu {
  text-decoration: none;
  color: #333;
  text-align: left;
  padding: 10px;
  display: none;
}

.show-menu_a {
  text-decoration: none;
  color: #333;
  text-align: left;
  padding: 0;
  display: none;
}

.session_price {
  margin-right: 3px;
  color: #333;
  position: relative;
  top: 5px;
  font-size: 14px;
}

.offer_price {
  background: var(--clr-primary);
  border: 1px solid #f7eecc;
  font-style: italic;
  font-weight: 700;
  color: #333;
  padding: 11px 15px;
  clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0 100%, 11% 50%, 0 0);
  position: absolute;
  top: 41px;
  right: 0;
}

input[type="checkbox"]:checked ~ #menu {
  display: block;
}

.language_type {
  width: 100%;
  background: #fff;
  border: none;
  color: #333;
  padding: 13px 20px;
  text-align: left;
  border-bottom: 1px solid #e7e7e7;
}

.language_type:hover {
  color: var(--clr-primary);
}

.book_Show {
  background: var(--clr-primary);
  padding: 2px 14px;
  border-radius: 8px;
  text-decoration: none;
  font-weight: 700;
  display: -webkit-inline-box;
  margin-bottom: 0;
}

#ContentPlaceHolder1_astrocontent p {
  color: #333;
}

.h3how_it_work {
  margin: 10px 0;
  padding: 0;
  font-size: 22px;
  color: #333;
  line-height: 30px;
  font-weight: 300;
  text-align: left;
}

.how_it_work_ol {
  margin: 0;
  padding: 0 0 0 17px;
  line-height: 30px;
}

.session_left_text {
  margin: 0;
  list-style: none;
  margin-left: 0;
  padding: 0;
}

.live_show_tile {
  background: #fff;
  padding: 10px;
  position: relative;
  display: flow-root;
  height: 107px;
}
@media only screen and (max-width: 600px) {
  .AstroSetu-title {
    display: none;
  }
  .AstroSetu-logo-res {
    padding-left: "1.2rem";
    margin-left: 2rem;
  }
}
