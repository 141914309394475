@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
body {
  margin: 0;
  font-family: var(--Roboto);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: var(--Roboto); */
}

:root {
  --Roboto: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  --clr-primary: #900f0d;
}
h1 {
  text-transform: uppercase;
  color: black !important;
}

span {
  font-family: var(--Roboto);
  font-weight: 400;
}
p {
  font-family: var(--Roboto);
  font-weight: 400;
}
