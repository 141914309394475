/* .style{
    font-style: ;
    font-display: ;
} */
.puja_image_size {
    width: 700px;
    height: 400px;
    border-radius: 10px;
}



.puja_temple_carousel {
    display: flex;
    width: 100%;
    justify-content: center;
}

.puja_temple_image_size {
    width: 700px;
    height: 400px;
    border-radius: 10px;
    margin-block: "1rem"
}

.puja_card {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1rem;
    margin-bottom: 1rem;
}